@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none;
  font-family: 'Poppins';
}
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-y: initial;
  background-color: #f2f2f2;
}
ul,
ol,
li a {
  list-style: none;
  cursor: pointer;
}
a {
  color: #07a5d4;
  cursor: pointer;
}
input {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

select,
button,
input,
input[type='email'],
input[type='number'],
input[type='text'] {
  outline: none;
  background-color: transparent;
}
input::placeholder {
  text-transform: none;
}
input[type='email'] {
  text-transform: none;
}
button,
select {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
}

.full-height {
  height: 100%;
}

table {
  border-radius: 0.5rem;
  border: 1px solid #40caff;
}

table,
th,
td {
  border-spacing: 0;
  overflow: hidden;
}

.card.full-height {
  height: calc(100% - 30px);
}

.hidden {
  display: none;
}

.MuiAccordionDetails-root {
  max-height: 180px;
  overflow-y: scroll;
}
